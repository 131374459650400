import { Injectable } from "@angular/core";
import * as moment from "moment-timezone";


@Injectable({
    providedIn: 'root'
})

export class DateUtilService {
    constructor() {
        moment.tz.setDefault('asia/kolkata');
    }

    getCurrentDate() {
        let tzDate = moment();
        return tzDate;
    }

    getCurrentDateWithYYYYMMDD() {
        let tzDate = this.getCurrentDate().format('YYYY-MM-DD');
        return tzDate;
    }

    getCurrentTimeWith12hrFormat() {
        let tzDate = this.getCurrentDate().format('hh:mm A');
        return tzDate;
    }


    getInputDateFormatToTime(input) {
        let tzDate = input.format('hh:mm A');
        return tzDate;
    }


    getCurrentDateWithTime() {
        let tzDate = this.getCurrentDate().format('YYYY-MM-DD hh:mm A');
        return tzDate;
    }

    addFiveDaysToCurrentDate() {
        const res = new Date();
        res.setDate(res.getDate() + 5);
        return res;
    }

    getFormatDate(date: string) {
        return moment(date, "YYYY-MM-DD").format('YYYY-MM-DD');;
    }

    sortingBasedOnEventDate(target: Array<any>) {
        return target.sort((b, a) => moment(a.eventDate).unix() - moment(b.eventDate).unix());
    }


    sortingBasedSubscriptionDate(target: Array<any>) {
        return target.sort((b, a) => moment(a.endDate).unix() - moment(b.endDate).unix());
    }

    getCurrentEpochTime() {
        return moment().unix();
    }

    getUnixTime(date: string) {
        return moment(date, "YYYY-MM-DD").unix();
    }

}