export class FirebaseCollection {
    public static BOARD_OF_EDUCATION = 'board_of_education';
    public static CLASSES = 'classes';
    public static SUBJECTS = 'subjects';
    public static MODULES = 'modules';
    public static EVENTS = 'events';
    public static PARAMETER = 'parameter';
    public static MY_ADDS = 'myadds';
    public static USER_EVENTS = 'user_events';
    public static USERS = 'users';
    public static QUESTIONS = 'questions';
    public static HUBS = 'hubs';
    public static VIDEO_UPLOAD = 'video_upload';
    public static IMAGE_UPLOAD = 'image_upload'
    public static STATE = 'state';
    public static DISTRICT = 'district';
    public static CITIES = 'cities';
    public static SCHOOL = 'school';
    public static ECLASS = 'eclass';
    public static all_rank = 'user_event_all_ranks';
    public static city_rank = 'user_event_city_ranks';
    public static district_rank ='user_event_district_ranks';
    public static school_rank = 'user_event_school_ranks';
    public static state_rank = 'user_event_state_ranks';
    public static INTERESTS ='interests';
    public static PARAMETER_TYPES ='parameter_types';
    public static COURSES ='courses';
    public static EVENT_INFORMATION ='event_information';
}
