// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCiBPfvk5jsRPyGn3_nnoKIzmRPGh_fe_w",
    authDomain: "tpoint-logicq.firebaseapp.com",
    projectId: "tpoint-logicq",
    storageBucket: "tpoint-logicq.appspot.com",
    messagingSenderId: "612540411272",
    appId: "1:612540411272:web:78d2fc851cffaae0501e3d",
    measurementId: "G-5K88JL1TN6"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
