import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userDetails = new ReplaySubject<any>(1);


    constructor() {

    }
    setUserDetails(user: any) {
        this.userDetails.next(user);
    }

    getUserDetails() {
        return this.userDetails.asObservable();
    }


}
