import { BaseProperty } from "./base-property";

export class Subjects extends BaseProperty{
    public classId: string;
    public className: string;

    public category : string ="Scholastic"; //scholastic/Co-scholastic
    public price : number;   // must be per month
    public categoryId: string;
    public expectedSalaryRangeLower:number;
    public expectedSalaryRangeUpper:number;
    public moduleCount:number;
    public duration:string;
    public faq:Faq[]=[
      {
        question:"",
        answer:""
      }
    ];
    public downloadableResourceCount:number = 1
}

export class Faq{
  question:string;
  answer:string;
}


 //Do not save this value to DB 'Only for View purpose'
 export class SubjectView extends Subjects {
    isChecked?: boolean;
    edit?: boolean;
}
