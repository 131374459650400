import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserService } from './services/user.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private fireAuth: AngularFireAuth, private firestore: AngularFirestore, private userService: UserService,  private alertController: AlertController,    private router: Router ) {
    this.fireAuth.authState.subscribe(user => {
      const currentUrl = this.router.url;
      if (null != user && null != user.uid) {
        this.firestore.collection("admin_user").doc(user.uid).get().subscribe((data: any) => {
          if (data.exists) {
            let user = data.data();
            this.userService.setUserDetails(user);
            // this.router.navigate(['/home/dashboard']);
          } else {
            if(currentUrl != '/login')
            {
              this.presentAlert('Please Login Again to Continue.');
            }
            this.fireAuth.signOut();
            this.router.navigate(['login']);
          }
        });
      } else {
        if(currentUrl != '/login')
        {
          this.presentAlert('Please Login Again to Continue.');
        }
        this.fireAuth.signOut();
        this.router.navigate(['login']);
      }
    });
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {

          }
        }
      ]
    })
    await alert.present();
  }
}
